import React from "react";
import { useTranslation } from "react-i18next";
import "./stayInformed.css";
import map from "../images/covid-map.png";
import info from "../images/info.svg";
import globe from "../images/globe.svg";


export default function Attention() {
  const { t, i18n } = useTranslation();
  const { resolvedLanguage } = i18n;

  const informedDiseaseLevels = `https://learn.makemytestcount.org${
    resolvedLanguage === "es" ? "/es-ES" : ""
  }/covid-and-flu-in-your-area`;
  const informedLearnMore = `https://learn.makemytestcount.org${
    resolvedLanguage === "es" ? "/es-ES" : ""
  }/frequently-asked-questions/home-testing`;

  return (
    <div className="container form-wrapper">
        <hr className="stayInformed hr" />
        <h1 className="with-rule">{t("informed-h1")}</h1>
        <p />
        <div className="col-2-md">
            <div className="next-steps-module">
                <div className="text-with-icon">
                    <img src={globe} alt="Globe" />
                    <div className="text-content">
                        <h2>{t("informed-disease-levels-h2")}</h2>
                        <p>{t("informed-disease-levels-p")}</p>
                        <a
                            href={informedDiseaseLevels}
                            target="_blank"
                            rel="noreferrer"
                        >
                        {t("informed-disease-levels-a")}
                        </a>
                    </div>
                </div>                
                <br />
                <div className="text-with-icon">
                    <img src={info} alt="Globe" />
                    <div className="text-content">
                        <h2>{t("informed-learn-more-h2")}</h2>
                        <p>{t("informed-learn-more-p")}</p>
                        <a
                            href={informedLearnMore}
                            target="_blank"
                            rel="noreferrer"
                        >
                        {t("informed-learn-more-a")}
                        </a>
                    </div>
                </div>
            </div>
            <div className="results-right-column">
                <div className="map highlighted" role="note">
                        <img src={map} alt="COVID & Flu Map" />
                </div>
            </div>
        </div>
    </div>
  );
}
import abbott_img from "../images/tests/abbott.png";
import quidel_img from "../images/tests/quidel.png";
import ihealth_img from "../images/tests/ihealth.png";
import clinitest from "../images/tests/clinitest.png";
import rocheOld from "../images/tests/roche.png";
import flowflex_img from "../images/tests/flowflex.png";
import anpnids from "../images/tests/anp-nda.png";
import bdveritor from "../images/tests/bd-veritor.png";
import boson from "../images/tests/boson.png";
import carestart from "../images/tests/carestart.png";
import celltrion from "../images/tests/celltrion.png";
import cue from "../images/tests/cue.png";
import detect from "../images/tests/detect.png";
import ellume from "../images/tests/ellume.png";
import genabio from "../images/tests/genabio.png";
import gotoknow from "../images/tests/gotoknow.png";
import hotgen from "../images/tests/hotgen.png";
import inbios from "../images/tests/inbios.png";
import indicaid from "../images/tests/indicaid.png";
import inteliswab from "../images/tests/inteliswab.png";
import lucira from "../images/tests/lucira.png";
import maxim from "../images/tests/maxim.png";
import metrix_nasal from "../images/tests/metrix-nasal.png";
import metrix_saliva from "../images/tests/metrix-saliva.png";
import ohc from "../images/tests/ohc.png";
import ongo from "../images/tests/ongo.png";
import ongoOne from "../images/tests/ongo-one.png";
import pfizer from "../images/tests/pfizer.png";
import pilot from "../images/tests/pilot.png";
import speedyswab from "../images/tests/speedy-swab.png";
import azure from "../images/tests/azure-fastep.png";
import cvs from "../images/tests/cvs.png";
import walgreens from "../images/tests/walgreens.png";
import advin from "../images/tests/advin.png";
import azurepen from "../images/tests/azure-pen.png";
import bioteke from "../images/tests/bioteke.png";
import cordx from "../images/tests/cordx.png";
import ctkbiotech from "../images/tests/ctkbiotech.png";
import genbody from "../images/tests/genbody.png";
import mologic from "../images/tests/mologic.png";
import nanoditech from "../images/tests/nano-ditech.png";
import oceanit from "../images/tests/oceanit.png";
import sekisui from "../images/tests/sekisui.png";
import immunostics from "../images/tests/immunostics.png";
import threeeo from "../images/tests/3eo.png";
import status from "../images/tests/status.png";
import osom_multiplex from "../images/tests/osom-multiplex.png";
import ihealth_multiplex from "../images/tests/ihealth-multiplex.png";
import welllife from "../images/tests/welllife.png";
import cordx_multiplex from "../images/tests/cordx-multiplex.png";
import ohc_multiplex from "../images/tests/ohc-multiplex.png";
import healgen_multiplex from "../images/tests/healgen-multiplex.png";
import flowflex_multiplex from "../images/tests/flowflex-multiplex.png";
import watmind_multiplex from "../images/tests/watmind-multiplex.png";
import btnx_multiplex from "../images/tests/btnx-multiplex.png";

// test kits mapped id to human readable name
const testKits = {
  iHealth: {
    name: "iHealth",
    alt: "Orange and gray box the with the iHealth logo the text 'COVID-19 Antigen Rapid Test' on the left and the text 'Self-Test At Home Results In 15 Mins' on the right.",
    altEs:
      "Cuadro naranja y gris con el logotipo de iHealth, el texto 'COVID-19 Antigen Rapid Test' a la izquierda y el texto 'Self-Test At Home Results In 15 Mins' a la derecha.",
    imageSrc: ihealth_img,
    diagnosticType: "antigen",
    euaDate: "2021-11-05",
  },
  AbbottBinaxNOW: {
    name: "Abbott BinaxNOW",
    alt: "Navy box with bright blue bottom stripe and the text 'BinaxNOW' in the middle.",
    altEs:
      "Caja azul marino con franja inferior azul brillante y el texto 'BinaxNOW' en el medio.",
    imageSrc: abbott_img,
    diagnosticType: "antigen",
    euaDate: "2020-12-16",
  },
  Flowflex: {
    name: "Flowflex",
    alt: "Grey box with the Flowflex logo, a coronavirus image, and the text 'COVID-19 Antigen Home Test' in the middle.",
    altEs:
      "Cuadro gris con el logotipo de Flowflex, una imagen de coronavirus y el texto 'COVID-19 Antigen Home Test' en el medio.",
    imageSrc: flowflex_img,
    diagnosticType: "antigen",
    euaDate: "2021-10-04",
  },
  Quidel: {
    name: "Quidel Quickvue",
    alt: "Grey box with a purple stripe across the top and two faces on the left side. The Quidel logo is in the top left and the text 'QuickVue At-Home OTC COVID-19 Test' is on the right.",
    altEs:
      "Caja gris con una franja morada en la parte superior y dos caras en el lado izquierdo. El logotipo de Quidel está en la parte superior izquierda y el texto 'QuickVue At-Home OTC COVID-19 Test' está a la derecha.",
    imageSrc: quidel_img,
    diagnosticType: "antigen",
    euaDate: "2021-03-01",
  },
  SiemensCLINITEST: {
    name: "CLINITEST",
    alt: "Grey box with an orange stripe across the bottom. The Siemens logo is in the top right and the text 'CLINITEST Rapid COVID-19 Antigen Self-Test' is on the left.",
    altEs:
      "Caja gris con una franja naranja en la parte inferior. El logotipo de Siemens está en la parte superior derecha y el texto 'CLINITEST Rapid COVID-19 Antigen Self-Test' está a la izquierda.",
    imageSrc: clinitest,
    diagnosticType: "antigen",
    euaDate: "2021-12-29",
  },
  Roche: {
    name: "Roche COVID-19 At-Home Test",
    alt: "Grey and blue box with the logo Roche in the bottom right corner, a photo of the covid test, and the text 'COVID-19 At-Home Test' across the top.",
    altEs:
      "Cuadro gris y azul con el logo de Roche en la esquina inferior derecha, una foto de la prueba de covid y el texto 'COVID-19 At-Home Test' en la parte superior.",
    imageSrc: rocheOld,
    diagnosticType: "antigen",
    euaDate: "2021-12-24",
  },
  "3EOHealth": {
    name: "3EO Health",
    alt: "Two-tone blue box with the 3EO Health logo in the top left, the text 'COVID-19 Test' in the bottom left, and an image of the test kit in white on the left.",
    altEs:
      "Cuadro azul de dos tonos con el logotipo de 3EO Health en la parte superior izquierda, el texto 'COVID-19 Test' en la parte inferior izquierda y una imagen del kit de prueba en blanco a la izquierda.",
    imageSrc: threeeo,
    diagnosticType: "molecular",
    euaDate: "2023-10-03",
  },
  AccessBioCareStart: {
    name: "CareStart",
    alt: "Purple box with the AccessBio logo in the top left, CareStart logo in the top middle, and the text 'COVID-19 Antigen Home Test' in the middle.",
    altEs:
      "Caja morada con el logotipo de AccessBio en la parte superior izquierda, logotipo de CareStart en la parte superior central y el texto 'COVID-19 Antigen Home Test' en el centro.",
    imageSrc: carestart,
    diagnosticType: "antigen",
    euaDate: "2021-08-02",
  },
  "anp-nids": {
    name: "NIDS",
    alt: "A tan box with the words 'NIDS Antigen Rapid Test Device' on top.",
    altEs:
      "Una caja color bronce con las palabras 'NIDS Antigen Rapid Test Device' en la parte superior.",
    imageSrc: anpnids,
    diagnosticType: "antigen",
    euaDate: "2021-09-27",
  },
  "Metrix-nasal": {
    name: "Metrix, Nasal",
    alt: "On the left, a black box with a purple and magenta ribbon on the right side, the Aptitude logo in the top left, and the text 'Metrix COVID-19 Test' in the middle. An image of a person using a nasal swab is on the right.",
    altEs:
      "A la izquierda, una caja negra con un lazo morado y magenta en el lado derecho, el logo de Aptitude en la esquina superior izquierda y el texto 'Metrix COVID-19 Test' en el medio. Una imagen de una persona usando un hisopo nasal está a la derecha.",
    imageSrc: metrix_nasal,
    diagnosticType: "molecular",
    euaDate: "2022-10-18",
  },
  "Metrix-saliva": {
    name: "Metrix, Saliva",
    alt: "On the left, a black box with a purple and magenta ribbon on the right side, the Aptitude logo in the top left, and the text 'Metrix COVID-19 Test' in the middle. An image of a person collecting saliva is on the right.",
    altEs:
      "A la izquierda, una caja negra con un lazo morado y magenta en el lado derecho, el logo de Aptitude en la esquina superior izquierda y el texto 'Metrix COVID-19 Test' en el medio. Una imagen de una persona recolectando saliva está a la derecha.",
    imageSrc: metrix_saliva,
    diagnosticType: "molecular",
    euaDate: "2022-10-18",
  },
  AzureFastep: {
    name: "FaStep",
    alt: "A white box with a large FaStep logo, the text 'Rapid, Reliable, Simple' along the bottom, and a purple triang in the top right corner.",
    altEs:
      "Una caja blanca con un gran logotipo de FaStep, el texto 'Rapid, Reliable, Simple' en la parte inferior y un triángulo morado en la esquina superior derecha.",
    imageSrc: azure,
    diagnosticType: "antigen",
    euaDate: "2022-11-21",
  },
  BDVeritor: {
    name: "BD Veritor At-Home",
    alt: "A box with a blue to yellow gradient from left to right, the BD Veritor logo in the top left, an image of a test and phone on the right side, and the text 'At-Home COVID-19 Test' on the left.",
    altEs:
      "Una caja con un degradado de azul a amarillo de izquierda a derecha, el logotipo de BD Veritor en la parte superior izquierda, una imagen de una prueba y un teléfono en el lado derecho, y el texto 'At-Home COVID-19 Test' en el lado izquierdo.",
    imageSrc: bdveritor,
    diagnosticType: "antigen",
    euaDate: "2021-11-22",
  },
  CelltrionDiaTrust: {
    name: "Celltrion DiaTrust",
    alt: "A blue box with a blue and white wave on the left side of the box and the text 'Celltrion DiaTrust COVID-19 Ag Home Test' in the middle.",
    altEs:
      "Una caja azul con una onda azul y blanca en el lado izquierdo de la caja y el texto 'Celltrion DiaTrust COVID-19 Ag Home Test' en el centro.",
    imageSrc: celltrion,
    diagnosticType: "antigen",
    euaDate: "2021-10-21",
  },
  Cue: {
    name: "Cue",
    alt: "A white minimal box with the Cue logo in the top right, an image of box on the bottom, and the text 'Cue COVID-19 Test For Home and Over The Counter (OTC) Use' on the right.",
    altEs:
      "Una caja blanca minimalista con el logotipo de Cue en la esquina superior derecha, una imagen de la caja en la parte inferior y el texto 'Cue COVID-19 Test For Home and Over The Counter (OTC) Use' a la derecha.",
    imageSrc: cue,
    diagnosticType: "molecular",
    euaDate: "2021-03-05",
  },
  Cvs: {
    name: "CVS",
    alt: "A blue box with a gray stripe across the top, the CVS Health logo in the top left, and the text 'At Home COVID-19 Test Kit' along the left side.",
    altEs:
      "Una caja azul con una franja gris en la parte superior, el logotipo de CVS Health en la esquina superior izquierda y el texto 'At Home COVID-19 Test Kit' a lo largo del lado izquierdo.",
    imageSrc: cvs,
    diagnosticType: "antigen",
    euaDate: "2021-03-01",
  },
  Detect: {
    name: "Detect",
    alt: "A gray box with test kit equipment on yellow pads viewed from the side and slightly above.",
    altEs:
      "Una caja gris con equipo de prueba en almohadillas amarillas visto desde el lado y ligeramente desde arriba.",
    imageSrc: detect,
    diagnosticType: "molecular",
    euaDate: "2021-11-22",
  },
  Ellume: {
    name: "Ellume",
    alt: "A bright teal box with the text 'ellume COVID-19 home test' in the middle.",
    altEs:
      "Una caja de color verde azulado brillante con el texto 'ellume COVID-19 home test' en el centro.",
    imageSrc: ellume,
    diagnosticType: "antigen",
    euaDate: "2020-12-15",
  },
  Genabio: {
    name: "Genabio",
    alt: "A gray box with a bright red and blue clock on the right side, the Genabio logo in the top left, and the text 'COVID-19 Rapid Self-Test Kit At Home Result in 15 min' on the left.",
    altEs:
      "Una caja gris con un reloj de colores rojo brillante y azul en el lado derecho, el logotipo de Genabio en la parte superior izquierda y el texto 'COVID-19 Rapid Self-Test Kit At Home Result in 15 min' a la izquierda.",
    imageSrc: genabio,
    diagnosticType: "antigen",
    euaDate: "2022-07-08",
  },
  Gotoknow: {
    name: "GoToKnow",
    alt: "A green, blue, and white box with the 'GoToKnow' logo in the top left, the text 'COVID-19 Antigen Rapid Test Results in 15 minutes' on the left, and the text 'Self-Test 2 tests' on the right.",
    altEs:
      "Una caja verde, azul y blanca con el logotipo de 'GoToKnow' en la parte superior izquierda, el texto 'COVID-19 Antigen Rapid Test Results in 15 minutes' a la izquierda y el texto 'Self-Test 2 tests' a la derecha.",
    imageSrc: gotoknow,
    diagnosticType: "antigen",
    euaDate: "2021-11-05",
  },
  Hotgen: {
    name: "Hotgen",
    alt: "A white box with a yellow and green wave across the bottom, the Hotgen logo in the top right, an image of a woman in profile in the upper right corner, and the text 'Coronavirus (2019-nCOVd)-Antigen test' in the middle.",
    altEs:
      "Una caja blanca con una onda amarilla y verde en la parte inferior, el logotipo de Hotgen en la esquina superior derecha, una imagen de una mujer en perfil en la esquina superior derecha y el texto 'Coronavirus (2019-nCOVd)-Antigen test' en el centro.",
    imageSrc: hotgen,
    diagnosticType: "antigen",
    euaDate: "2022-11-17",
  },
  Immunostics: {
    name: "Immunostics Swab-N-Go",
    alt: "A white box with the Immunostics, Inc logo in the top left and the navy and teal text  'Swab-N-Go Home Test COVID-19 Ag' in the middle.",
    altEs:
      "Una caja blanca con el logotipo de Immunostics, Inc en la parte superior izquierda y el texto en azul marino y verde azulado 'Swab-N-Go Home Test COVID-19 Ag' en el centro.",
    imageSrc: immunostics,
    diagnosticType: "antigen",
    euaDate: "2023-07-17",
  },
  InBios: {
    name: "InBios",
    alt: "A white box with the InBios logo in the top right and large green and blue text of 'COVID-19 ANTIGEN SELF-TEST' in the middle.",
    altEs:
      "Una caja blanca con el logotipo de InBios en la parte superior derecha y un gran texto en verde y azul que dice 'COVID-19 ANTIGEN SELF-TEST' en el centro.",
    imageSrc: inbios,
    diagnosticType: "antigen",
    euaDate: "2021-11-22",
  },
  "IntrivoOn/Go": {
    name: "On/Go",
    alt: "A yellow box with a large 'On/Go' logo in the top and blue text 'COVID-19 Antigen Self-Test' in the middle.",
    altEs:
      "Una caja amarilla con un gran logotipo de 'On/Go' en la parte superior y el texto en azul 'COVID-19 Antigen Self-Test' en el centro.",
    imageSrc: ongo,
    diagnosticType: "antigen",
    euaDate: "2021-08-02",
  },
  "IntrivoOn/GoOne": {
    name: "On/Go One",
    alt: "A yellow box with large 'On/Go one' logo in the top and blue text 'COVID-19 Antigen Home Test' along the bottom.",
    altEs:
      "Una caja amarilla con el gran logotipo 'On/Go one' en la parte superior y el texto en azul 'COVID-19 Antigen Home Test' a lo largo de la parte inferior.",
    imageSrc: ongoOne,
    diagnosticType: "antigen",
    euaDate: "2021-10-04",
  },
  Lucira: {
    name: "Lucira CHECK-IT",
    alt: "A dark blue box with a light blue cross and the test 'LUCIRA CHECK IT COVID-19' in the middle.",
    altEs:
      "Una caja azul oscuro con una cruz azul claro y el texto 'LUCIRA CHECK IT COVID-19' en el centro.",
    imageSrc: lucira,
    diagnosticType: "molecular",
    euaDate: "2021-04-09",
  },
  MaximBioClearDetect: {
    name: "ClearDetect",
    alt: "A White box with a blue and purple gradient circle, the 'Maxim Bio' logo in the top right, the 'Clear Detect' logo in the middle, and the text 'COVID-19 Antigen Home Test' on the right.",
    altEs:
      "Una caja blanca con un círculo de degradado azul y morado, el logotipo de 'Maxim Bio' en la esquina superior derecha, el logotipo de 'Clear Detect' en el medio y el texto 'COVID-19 Antigen Home Test' a la derecha.",
    imageSrc: maxim,
    diagnosticType: "antigen",
    euaDate: "2022-01-19",
  },
  INDICAID: {
    name: "INDICAID",
    alt: "A white box with a purple strip, a large 'INDICAID' logo in the top left, the text 'COVID-19 Rapid Antigen At-Home Test' along the bottom, and an image of the text on the right.",
    altEs:
      "Una caja blanca con una franja morada, un gran logotipo de 'INDICAID' en la parte superior izquierda, el texto 'COVID-19 Rapid Antigen At-Home Test' a lo largo de la parte inferior y una imagen del texto a la derecha.",
    imageSrc: indicaid,
    diagnosticType: "antigen",
    euaDate: "2022-03-26",
  },
  InteliSwab: {
    name: "InteliSwab",
    alt: "A purple gradient box with the 'InteliSwab' logo and the text 'COVID-19 Rapid Test' in the middle.",
    altEs:
      "Una caja con degradado de color morado con el logotipo de 'InteliSwab' y el texto 'COVID-19 Rapid Test' en el medio.",
    imageSrc: inteliswab,
    diagnosticType: "antigen",
    euaDate: "2021-06-04",
  },
  Osang: {
    name: "OHC",
    alt: "A white box with the OHC logo in the top left, the blue text 'OHC COVID-19 Antigen Self-Test' on the left, and a blue outline of a family on the right.",
    altEs:
      "Una caja blanca con el logotipo de OHC en la esquina superior izquierda, el texto azul 'OHC COVID-19 Antigen Self-Test' a la izquierda y un contorno azul de una familia a la derecha.",
    imageSrc: ohc,
    diagnosticType: "antigen",
    euaDate: "2022-04-06",
  },
  SDBiosensorPilot: {
    name: "Pilot",
    alt: "A white box with purple and blue triangles in the upper left, the 'Pilot' logo in the middle, and the text 'COVID-19 AT-Home Test' on the right.",
    altEs:
      "Una caja blanca con triángulos morados y azules en la parte superior izquierda, el logotipo de 'Pilot' en el medio y el texto 'COVID-19 AT-Home Test' a la derecha.",
    imageSrc: pilot,
    diagnosticType: "antigen",
    euaDate: "2021-12-24",
  },
  SpeedySwab: {
    name: "SpeedySwab",
    alt: "A bright blue and purple package with the 'Speedy Swab' logo and an image of a nose swab in the middle and the text 'Rapid Antigen Nasal Swab Self-test' underneath.",
    altEs:
      "Un paquete de color azul brillante y morado con el logotipo de 'Speedy Swab' y una imagen de un hisopo nasal en el medio y el texto 'Rapid Antigen Nasal Swab Self-test' debajo.",
    imageSrc: speedyswab,
    diagnosticType: "antigen",
    euaDate: "2022-07-08",
  },
  PrincetonStatus: {
    name: "lifeSign Status",
    alt: "A white box with a grid of black dots with a gradient towards grey on the left, the 'lifeSign' logo on the top, and the text 'Status COVID-19 Antigen Rapid Test for Home Use' on the right.",
    altEs:
      "Una caja blanca con una cuadrícula de puntos negros con un degradado hacia gris en la izquierda, el logotipo de 'lifeSign' en la parte superior y el texto 'Status COVID-19 Antigen Rapid Test for Home Use' a la derecha.",
    imageSrc: status,
    diagnosticType: "antigen",
    euaDate: "2023-04-24",
  },
  Walgreens: {
    name: "Walgreens",
    alt: "A dark blue box with the Walgreens logo across the top and the white text 'At-Home COVID-19 Test Kit' in the middle.",
    altEs:
      "Una caja azul oscuro con el logotipo de Walgreens en la parte superior y el texto en blanco 'At-Home COVID-19 Test Kit' en el medio.",
    imageSrc: walgreens,
    diagnosticType: "antigen",
    euaDate: "2021-03-01",
  },
  Xiamen: {
    name: "Boson",
    alt: "A white box with a blue and green stripe on the bottom, a large 'Boson' logo in the upper left, and the text 'Rapid SARS-CoV-2 Antigen Test Card Home Test' on the right.",
    altEs:
      "Una caja blanca con una franja azul y verde en la parte inferior, un gran logotipo de 'Boson' en la esquina superior izquierda y el texto 'Rapid SARS-CoV-2 Antigen Test Card Home Test' a la derecha.",
    imageSrc: boson,
    diagnosticType: "antigen",
    euaDate: "2022-04-06",
  },
  Advin: {
    name: "Advin @Home",
    alt: "A white box with a blue stripe, a large 'Advin' logo in the top left, and the text 'Advin COVID-19 Antigen Test @Home' across the top.",
    altEs:
      "Una caja blanca con una franja azul, un gran logotipo de 'Advin' en la parte superior izquierda y el texto 'Advin COVID-19 Antigen Test @Home' en la parte superior.",
    imageSrc: advin,
    diagnosticType: "antigen",
    euaDate: "2022-12-22",
  },
  AzureFastepPen: {
    name: "FaStep Pen",
    alt: "A blue to white gradient box with the FaStep logo in the top and blue text of 'COVID-19 ANTIGEN PEN HOME TEST' in the middle.",
    altEs:
      "Una caja de degradado de azul a blanco con el logotipo de FaStep en la parte superior y el texto azul 'COVID-19 ANTIGEN PEN HOME TEST' en el medio.",
    imageSrc: azurepen,
    diagnosticType: "antigen",
    euaDate: "2023-04-14",
  },
  BioTeke: {
    name: "BioTeke Bio-Self",
    alt: "A tan box with the BioTeke logo in the top left and the text 'SARS-CoV-2 Antigen Test Kit Home use/Personal test' in the middle.",
    altEs:
      "Una caja color crema con el logotipo de BioTeke en la parte superior izquierda y el texto 'SARS-CoV-2 Antigen Test Kit Home use/Personal test' en el medio.",
    imageSrc: bioteke,
    diagnosticType: "antigen",
    euaDate: "2023-05-22",
  },
  CorDx: {
    name: "CorDx",
    alt: "An off white-box with a gray horizontal stripe, CorDx's logo in the top left and the text 'COVID-19 Ag Test At Home OTC' along the bottom.",
    altEs:
      "Una caja de color blanco crema con una franja horizontal gris, el logotipo de CorDx en la esquina superior izquierda y el texto 'COVID-19 Ag Test At Home OTC' en la parte inferior.",
    imageSrc: cordx,
    diagnosticType: "antigen",
    euaDate: "2022-11-21",
  },
  CTKBiotech: {
    name: "ImmuView",
    alt: "A white box with 'IMMUVIEW COVID-19 Antigen Home Test' and a green stripe along the bottom.",
    altEs:
      "Una caja blanca con 'IMMUVIEW COVID-19 Antigen Home Test' y una franja verde a lo largo de la parte inferior.",
    imageSrc: ctkbiotech,
    diagnosticType: "antigen",
    euaDate: "2022-12-20",
  },
  GenBody: {
    name: "GenBody",
    alt: "A white box with a patterned fuschia stripe on the left, the fushcia text 'GenBody COVID-19 Ag' in the middle, and a picture of a test on the right.",
    altEs:
      "Una caja blanca con una franja fucsia con patrones en el lado izquierdo, el texto fucsia 'GenBody COVID-19 Ag' en el centro y una imagen de una prueba en el lado derecho.",
    imageSrc: genbody,
    diagnosticType: "antigen",
    euaDate: "2023-02-27",
  },
  Mologic: {
    name: "COVI-Go",
    alt: "A green box with white lettering for 'COVID-Go SARS-CoV-2 Ag Self-Test' in the middle.",
    altEs:
      "Una caja verde con letras blancas que dicen 'COVID-Go SARS-CoV-2 Ag Self-Test' en el medio.",
    imageSrc: mologic,
    diagnosticType: "antigen",
    euaDate: "2023-02-22",
  },
  NanoDitech: {
    name: "Nano-Check",
    alt: "A light purple box with a white circle containing purple text of 'Nano-Check COVID-19 Antigen At-Home Test' in the top left.",
    altEs:
      "Una caja de color morado claro con un círculo blanco que contiene el texto morado 'Nano-Check COVID-19 Antigen At-Home Test' en la esquina superior izquierda.",
    imageSrc: nanoditech,
    diagnosticType: "antigen",
    euaDate: "2023-05-12",
  },
  Oceanit: {
    name: "Assure-100",
    alt: "A white box with the Assure logo on the left and the text 'Assure-100 Rapid COVID-19 Home Test' in the middle.",
    altEs:
      "Una caja blanca con el logotipo de Assure en la izquierda y el texto 'Assure-100 Rapid COVID-19 Home Test' en el medio.",
    imageSrc: oceanit,
    diagnosticType: "antigen",
    euaDate: "2022-12-22",
  },
  Sekisui: {
    name: "OSOM",
    alt: "A white box with the yellow osom logo and purple text 'COVID-19 Antigen Rapid Test Home Test' on the left.",
    altEs:
      "Una caja blanca con el logotipo amarillo de Osom y el texto morado 'COVID-19 Antigen Rapid Test Home Test' en la izquierda.",
    imageSrc: sekisui,
    diagnosticType: "antigen",
    euaDate: "2022-11-17",
  },
  "Lucira-Multiplex": {
    name: "Lucira by Pfizer",
    alt: "A white box with a blue geometric pattern on the left, the Lucira logo in the top, and the blue text for 'COVID-19 & Flu Test' in the middle.",
    altEs:
      "Una caja blanca con un patrón geométrico azul en el lado izquierdo, el logotipo de Lucira en la parte superior y el texto azul que dice 'COVID-19 & Flu Test' en el centro.",
    imageSrc: pfizer,
    diagnosticType: "molecular",
    euaDate: "2023-02-24",
  },
  "OSOM-Multiplex": {
    name: "OSOM Flu SARS-CoV-2 Combo Test ",
    alt: "An orange box with a blue front, logos in the bottom right, and the white text 'OSOM Flu SARS-CoV-2 Combo Test' in the top left.",
    altEs:
      "Una caja naranja con el frente azul, logotipos en la parte inferior derecha y el texto blanco 'OSOM Flu SARS-CoV-2 Combo Test' en la parte superior izquierda.",
    imageSrc: osom_multiplex,
    diagnosticType: "antigen",
    euaDate: "2024-02-29",
  },
  "iHealth-Multiplex": {
    name: "iHealth COVID-19, Flu A&B, 3-in-1",
    alt: "An orange and white box with the iHealth logo on the left and 'COVID-19 Flu A&B 3-in-1' on the right.",
    altEs:
      "Una caja naranja y blanca con el logotipo de iHealth a la izquierda y 'COVID-19 Flu A&B 3-in-1' a la derecha.",
    imageSrc: ihealth_multiplex,
    diagnosticType: "antigen",
    euaDate: "2024-05-07",
  },
  "WellLife": {
    name: "WellLife Home Test",
    alt: "A purple and white box with the Well life logo in the middle and the text 'COVID-19/Influenza A&B Home Test' in the middle.",
    altEs:
    "Una caja morada y blanca con el logotipo de Well Life en el centro y el texto 'COVID-19/Influenza A&B Home Test' en el medio.",
    imageSrc: welllife,
    diagnosticType: "antigen",
    euaDate: "2024-04-30",
  },
  "CorDx-Multiplex": {
    name: "CorDx Tyfast",
    alt: "A white and blue box with the CorDx logo in the top left and Tyfast in the top right in an orange box. The text 'Flu A/B & COVID-19 At Home Multiplex Rapid Test' is in the middle.",
    altEs:
    "Una caja blanca y azul con el logotipo de CorDx en la parte superior izquierda y Tyfast en la parte superior derecha dentro de un cuadro naranja. El texto 'Flu A/B & COVID-19 At Home Multiplex Rapid Test' está en el centro.",
    imageSrc: cordx_multiplex,
    diagnosticType: "antigen",
    euaDate: "2024-04-05",
  },
  "OHC-Multiplex": {
    name: "OHC QuickFinder",
    alt: "A blue box with white lettering. The OHC Osang Healthcare logo is in the left top with the text 'QuickFinder COVID-19/Flu Antigen Self Test' below. There is an image of a test strip on the right.",
    altEs:
    "Una caja azul con letras blancas. El logotipo de OHC Osang Healthcare está en la parte superior izquierda con el texto 'QuickFinder COVID-19/Flu Antigen Self Test' debajo. Hay una imagen de una tira de prueba a la derecha.",
    imageSrc: ohc_multiplex,
    diagnosticType: "antigen",
    euaDate: "2024-04-03",
  },
  "Healgen-Multiplex": {
    name: "Healgen Combo Rapid Test",
    alt: "A white box with a blue strip on the left showing the Healgen logo and a sample test strip below. The text 'COVID-19/Flu A&B Ag Combo Rapid Test Cassette (Swab)' is in the middle.",
    altEs:
    "Una caja blanca con una franja azul a la izquierda mostrando el logotipo de Healgen y una tira de prueba de muestra debajo. El texto 'COVID-19/Flu A&B Ag Combo Rapid Test Cassette (Swab)' está en el medio.",
    imageSrc: healgen_multiplex,
    diagnosticType: "antigen",
    euaDate: "2024-06-10",
  },
  "Flowflex-Multiplex": {
    name: "Flowflex Plus",
    alt: "A white box with a blue wave on the bottom showing the Flowfex Plus logo up top. The text 'COVID-19 and Flu A/B Home Test' is under the logo.",
    altEs:
    "Caja blanca con una ola azul en la parte inferior que muestra el logotipo de Flowfex Plus en la parte superior. El texto ‘COVID-19 and Flu A/B Home Test’ está debajo del logotipo.",
    imageSrc: flowflex_multiplex,
    diagnosticType: "antigen",
    euaDate: "2024-07-23",
  },
  "Watmind-Multiplex": {
    name: "Speedy Swab",
    alt: "A white and orange box face with the Watmind logo in the top left corner. The text 'Speedy Swab Rapid COVID-19 + Flu A & B Antigen Self-Test' is in the middle.",
    altEs:
    "Una caja blanca y naranja con el logotipo de Watmind en la esquina superior izquierda. El texto 'Speedy Swab Rapid COVID-19 + Flu A & B Antigen Self-Test' está en el centro.",
    imageSrc: watmind_multiplex,
    diagnosticType: "antigen",
    euaDate: "2024-05-24",
  },
  "BTNX-Multiplex": {
    name: "BTNX Rapid Response",
    alt: "A white and green box face with the BTNX logo in the top left corner. The text 'Rapid Response Influenza AB + COVID-19 Antigen Detection Test' is in the middle. An image of the test strip is in the right bottom corner.",
    altEs:
    "Una caja blanca y verde con el logotipo de BTNX en la esquina superior izquierda. El texto ‘Rapid Response Influenza AB + COVID-19 Antigen Detection Test’ está en el centro. Una imagen de la tira de prueba se encuentra en la esquina inferior derecha.",
    imageSrc: btnx_multiplex,
    diagnosticType: "antigen",
    euaDate: "2025-01-10",
  },
};

export const multiplexTestKits = [
  "BTNX-Multiplex",
  "CorDx-Multiplex",
  "Flowflex-Multiplex",
  "Healgen-Multiplex",
  "iHealth-Multiplex",
  "Lucira-Multiplex",
  "OHC-Multiplex",
  "OSOM-Multiplex",
  "Watmind-Multiplex",
  "WellLife",
];

export const testKitsPrimary = [
  "AbbottBinaxNOW",
  "iHealth",
  "Flowflex",
  "AccessBioCareStart",
  "InteliSwab",
  "Osang",
  "Quidel",
  "Roche",
];

export const testKitsSecondary = [
  "3EOHealth",
  "Advin",
  "Oceanit",
  "BDVeritor",
  "BioTeke",
  "Xiamen",
  "CelltrionDiaTrust",
  "MaximBioClearDetect",
  "SiemensCLINITEST",
  "Mologic",
  "CorDx",
  "Cvs",
  "Cue",
  "Detect",
  "Ellume",
  "AzureFastep",
  "AzureFastepPen",
  "Genabio",
  "GenBody",
  "Gotoknow",
  "Hotgen",
  "CTKBiotech",
  "Immunostics",
  "InBios",
  "INDICAID",
  "PrincetonStatus",
  "Lucira",
  "Metrix-nasal",
  "Metrix-saliva",
  "NanoDitech",
  "anp-nids",
  "IntrivoOn/Go",
  "IntrivoOn/GoOne",
  "Sekisui",
  "SDBiosensorPilot",
  "SpeedySwab",
  "Walgreens",
];

export default testKits;
